import { USERPROJECTS_PAGE_KEY } from 'src/pages/pagesKeys';

//import { isSessionValid } from 'src/core/login/LoginService';

import GenericItemPage, { DOM_ID } from 'src/pages/generic-item-page/GenericItemPage';
import UserProjectContent from './UserProjectContent';

export default {
  key: USERPROJECTS_PAGE_KEY,
  path: '/userprojects',
  elId: DOM_ID,
  className: 'user-project-page',
  component: GenericItemPage,
  childComponent: UserProjectContent,
  //isGranted: () => isSessionValid(),
  relatedDataToFetch: ['exhibitor'],
};
