import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { DATA_TYPE_USERPROJECTS } from 'data/config/dataConfig';
import { USERPROJECTS_PAGE_KEY } from 'src/pages/pagesKeys';

import DetailExhibitor from 'src/components/detail-exhibitor/DetailExhibitor';
import DetailFixedTitle from 'src/components/detail-fixed-title/DetailFixedTitle';
import Description from 'src/components/fiche/Description';
import ShareButton from 'src/components/share-button/ShareButton';
import NoteButton from 'src/components/notes/NoteButton';
import PhoneRow from 'src/components/fiche/PhoneRow';
import CompanyRow from 'src/components/fiche/CompanyRow';
import RoleRow from 'src/components/fiche/RoleRow';
import FullNameRow from 'src/components/fiche/FullNameRow';
import EmailRow from 'src/components/fiche/EmailRow';
import WebsiteRow from 'src/components/fiche/WebsiteRow';
import DetailSocial from 'src/components/detail-social/DetailSocial';

import GenericIcon from 'src/components/generic-icon/GenericIcon';

import '../common-fiche.scss';
import './UserProjectPage.scss';

class UserProjectContent extends PureComponent {
  setScrollableContentEl = (el) => {
    this.contentContainerEl = el;
  };
  scrollTo = (value) => {
    if (this.contentContainerEl) {
      this.contentContainerEl.scrollTop = value;
    }
  };

  onClickOnFavIcon = () => {
    this.props.actions.toggleFavorite(
      this.props.item.id,
      DATA_TYPE_USERPROJECTS,
      this.props.isFavorite
    );
  };

  render() {
    let lump = this.props.item.lump;

    const title = this.props.item.title,
      logoUrl = this.props.item.photo_file_name,
      description = this.props.item.description,
      hasAddress =
        this.props.item.address ||
        this.props.item.postal_code ||
        this.props.item.city ||
        this.props.item.references.country,
      hasSocial = lump.social && (lump.social.tw || lump.social.fb || lump.social.ln),
      hasContactsBlock =
        (lump.contacts && lump.contacts.length > 0) ||
        this.props.item.phone ||
        this.props.item.email ||
        this.props.item.website ||
        hasAddress ||
        hasSocial;
    return (
      <div className="fiche newproduct content-font content-below-apptoolbar">
        {/* title */}
        <DetailFixedTitle
          title={title}
          isFav={this.props.isFavorite}
          onFavClick={this.onClickOnFavIcon}
          labels={this.props.labels}
        >
          <NoteButton
            itemTitle={this.props.pageTitle}
            itemId={this.props.item.id}
            dataType={DATA_TYPE_USERPROJECTS}
            hasNote={this.props.hasNote}
            labels={this.props.labels}
            actions={this.props.actions}
          />
          <ShareButton
            name={this.props.pageTitle}
            queryString={this.props.queryString}
            description={description}
            image={logoUrl}
            labels={this.props.labels}
            actions={this.props.actions}
            profile={this.props.profile}
            pageKey={USERPROJECTS_PAGE_KEY}
          />
        </DetailFixedTitle>

        <div className="content" ref={this.setScrollableContentEl}>
          <div className="all-informations content-font">
            <Description
              logoFileName={logoUrl}
              dataType={DATA_TYPE_USERPROJECTS}
              description={description}
              isLogoFullWidth={this.props.isLogoFullWidth}
            />

            {/* Exhibitor*/}
            <DetailExhibitor
              exhibitor={this.props.item.references.exhibitor}
              actions={this.props.actions}
              labels={this.props.labels}
            />

            {/* Market date */}
            {this.props.item.lump.date_market && (
              <div className="free-row">
                <div className="prop-img">
                  <div className="prop-left label">
                    <div className="color-grey-dark">
                      <span>{this.props.labels.userprojects.launchDate}</span>
                    </div>
                  </div>
                  <div className="prop-right name-label">
                    <div className="color-grey-dark ">
                      <span>{this.props.item.lump.date_market}</span>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {hasContactsBlock && (
              <div>
                {lump.contacts && lump.contacts.length > 0 && (
                  <div className="fiche-separator">{this.props.labels.exhibitor.contacts}</div>
                )}
                <DetailSocial data={lump.social} actions={this.props.actions} />

                {lump.contacts && lump.contacts.length > 0 && (
                  <div className="free-row">
                    <div className="prop-img">
                      <div className="prop-left">
                        <div className="fiche-contact-icon">
                          <GenericIcon className="user-icon" />
                        </div>
                      </div>
                      <div className="prop-right">
                        {lump.contacts.map((entry, index) => (
                          <div key={index}>
                            <span
                              className="contactName"
                              dangerouslySetInnerHTML={{ __html: entry.n }}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
                <div className="fiche-separator">{this.props.labels.data.contacts.singular}</div>

                <FullNameRow
                  civility={this.props.item.civility}
                  firstname={this.props.item.firstname}
                  lastname={this.props.item.lastname}
                />

                <RoleRow role={this.props.item.role} />

                <CompanyRow company={this.props.item.company} />

                <PhoneRow phone={this.props.item.phone} />

                <WebsiteRow website={this.props.item.website} actions={this.props.actions} />

                <EmailRow email={this.props.item.email} />

                {/* address */}
                {hasAddress && (
                  <div className="free-row">
                    <div className="prop-img">
                      <div className="prop-left">
                        <div className="fiche-contact-icon">
                          <GenericIcon
                            src={'icons-svg/fiche/university-solid.svg'}
                            className="university-icon fiche-icon"
                          />
                        </div>
                      </div>
                      <div className="prop-right">
                        {this.props.item.address && (
                          <div dangerouslySetInnerHTML={{ __html: this.props.item.address }} />
                        )}
                        {this.props.item.postal_code && (
                          <div dangerouslySetInnerHTML={{ __html: this.props.item.postal_code }} />
                        )}
                        {this.props.item.city && (
                          <div dangerouslySetInnerHTML={{ __html: this.props.item.city }} />
                        )}
                        {this.props.item.references.country && (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: this.props.item.references.country.title,
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

UserProjectContent.propTypes = {
  item: PropTypes.object,
  isFavorite: PropTypes.bool,
  isLogoFullWidth: PropTypes.bool,
  // Common
  queryString: PropTypes.string.isRequired,
  pageTitle: PropTypes.string.isRequired,
  labels: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

export default UserProjectContent;
