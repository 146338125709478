import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

//import { DATA_TYPE_USERPROJECTS } from 'data/config/dataConfig';
import { LIST_PAGE_KEY, USER_DATA_PAGE_KEY } from 'src/pages/pagesKeys';

import { isChecked } from './FilterDialogHelpers';
import Tabs from 'src/components/tabs/Tabs';
import * as actions from 'src/store/actions';

import GenericIcon from 'src/components/generic-icon/GenericIcon';

import './FilterDialog.scss';

export const COMPONENT_KEY = 'FilterDialog';

const initialState = {
  unfoldedCats: [],
  navigatedTo: null,
};

class FilterDialog extends React.Component {
  state = Object.assign({}, initialState);

  reset() {
    this.setState(initialState);
  }

  setTabsRef = (ref) => {
    this.tabs = ref;
  };

  // --------------------
  //  Top categories row

  isTopCatSelected = (topCatId) => topCatId === this.props.selectedTopCatId;

  onTabClick = (index) => {
    this.topCatClicked(null, this.props.topCats[index].id, index);
  };

  topCatClicked = (ev, catId, index) => {
    if (this.state.navigatedTo) {
      this.resetCategoryNavigation();
    }
    this.resetScroll();
    this.props.actions.filterTopCatSelected(
      catId || parseInt(ev.target.id, 10),
      this.props.dataType,
      index
    );
  };

  // --------------------------
  //  Sub categories hierarchy

  getSubCatRow = (subCat, navInsteadOfToggle) => {
    let isNavigatedCat = false;
    if (this.state.navigatedTo) {
      isNavigatedCat = subCat.id === this.state.navigatedTo;
      if (!isNavigatedCat && subCat.parent_ids.indexOf(this.state.navigatedTo) === -1) {
        return null;
      }
    }

    if (subCat.counter === 0) {
      // Skip empty categories
      return null;
    }

    let hasSubCats = this.atLeastOneSubCategoryHasItems(subCat);
    let isUnfolded = this.isUnfolded(subCat.id);

    let onClick;
    if (hasSubCats && navInsteadOfToggle) {
      onClick = this.navigateToCategory;
    } else if (hasSubCats) {
      onClick = this.toggleSubCatContent;
    } else {
      onClick = this.categoryToggle;
    }

    let subCatRow = (
      <div
        key={subCat.id}
        data-id={subCat.id}
        className={
          'fd-subcat' +
          (hasSubCats ? ' fd-subcat-clickable' : '') +
          (isNavigatedCat ? ' is-navigated-cat' : '')
        }
        onClick={onClick}
      >
        {/* checkbox */}
        {this.getCheckbox(subCat)}

        {/* title */}
        <span className="fd-subcat-title">{subCat.title}</span>

        {isNavigatedCat ? (
          <GenericIcon src="icons-svg/control/arrow-alt-circle-up-solid.svg" className="fd-cat-nav-back-icon arrow-alt-circle-up-icon"></GenericIcon>
        ) : null}

        {/* chevron */}
        {hasSubCats && (
          <GenericIcon
            st0Class="chevron-right-icon-st0" src="icons-svg/control/chevron-droit-solid.svg" 
            className={
              'fd-chevron chevron-right-icon' + (isUnfolded ? ' fd-chevron-unfolded' : '')
            }
          ></GenericIcon>
        )}
      </div>
    );
    if (isUnfolded === false) {
      return subCatRow;
    } else {
      return (
        <div key={subCat.id} className={isNavigatedCat ? 'fd-content-wrapper' : null}>
          {subCatRow}
          <div className="fd-subsubcats">
            {(subCat.cats || []).map((subSubCat) => this.getSubCatRow(subSubCat, false))}
          </div>
        </div>
      );
    }
  };

  navigateToCategory = (ev) => {
    this.toggleCategoryNavigation(parseInt(ev.target.dataset.id, 10));
  };
  resetCategoryNavigation = () => {
    this.toggleCategoryNavigation(this.state.navigatedTo);
  };
  toggleCategoryNavigation(catId) {
    this.resetScroll();
    if (this.state.navigatedTo === catId) {
      // go back
      this.setState({ navigatedTo: null });
      this.fold(catId);
    } else {
      this.setState({ navigatedTo: catId });
      this.unfold(catId);
    }
  }

  toggleSubCatContent = (ev) => {
    let catId = parseInt(ev.target.dataset.id, 10);

    if (this.isUnfolded(catId)) {
      // Remove from array of unfolded cat ids
      this.fold(catId);
    } else {
      // Add to array of unfolded cat ids
      this.unfold(catId);
    }
  };

  fold(catId) {
    this.setState({ unfoldedCats: this.state.unfoldedCats.filter((_catId) => _catId !== catId) });
  }

  unfold(catId) {
    this.setState({ unfoldedCats: this.state.unfoldedCats.concat(catId) });
  }

  setContentEl = (el) => {
    this.contentEl = el;
  };

  resetScroll = () => {
    if (this.contentEl) {
      this.contentEl.scrollTop = 0;
    }
  };

  // ----------------
  //  Common helpers

  hasSubCats = (cat) => Array.isArray(cat.cats) && cat.cats.length > 0;

  atLeastOneSubCategoryHasItems = (cat) => {
    if (this.hasSubCats(cat)) {
      return cat.cats.reduce((currentCount, _cat) => currentCount + _cat.counter, 0) > 0;
    }
    return false;
  };

  getCheckbox = (cat, isTopCat) => (
    <span data-id={cat.id} className="fd-checkbox" onClick={this.categoryToggle}>
      <span className={this.getCheckboxClassName(cat, isTopCat)}></span>
    </span>
  );

  hasDescendantChecked(cat) {
    for (let i = 0; i < cat.descendants_cat_ids.length; i++) {
      let checkedSubCats = this.props.checkedCats[this.props.selectedTopCatId];
      if (
        Array.isArray(checkedSubCats) &&
        checkedSubCats.indexOf(cat.descendants_cat_ids[i]) !== -1
      ) {
        return true;
      }
    }
    return false;
  }

  getCheckboxClassName = (cat, isTopCat) => {
    // If there are checked cats for displayed top cat
    if (Object.keys(this.props.checkedCats).length > 0) {
      // CHECK = all
      if (isChecked(this.props, cat.id)) {
        return 'fd-selected check-icon';
      }

      // MINUS = partial
      let minusClasses = 'fd-selected minus-icon';
      if (isTopCat) {
        if (this.props.checkedCats[cat.id] && this.props.checkedCats[cat.id].length > 0) {
          return minusClasses;
        }
      }
      // Has sub cat(s) checked
      else if (Array.isArray(cat.cats) && this.hasDescendantChecked(cat)) {
        return minusClasses;
      }
    }

    // NONE - Cat isn't checked nor any sub cat
    return 'fd-unchecked';
  };

  isTopCat = (catId) => !!this.props.topCats.find((cat) => catId === cat.id);

  isUnfolded = (catId) => this.state.unfoldedCats.indexOf(catId) !== -1;

  categoryToggle = (ev) => {
    ev.stopPropagation();
    let catId = ev.target.dataset.id || ev.target.parentNode.dataset.id;
    if (catId) {
      catId = parseInt(catId, 10);

      let isTopCat = this.isTopCat(catId),
        isSelectedTopCat = isTopCat && catId === this.props.selectedTopCatId;
      if (isTopCat && !isSelectedTopCat) {
        // auto select top cat
        let tabIndex = this.props.topCats.map((cat) => cat.id).indexOf(catId);
        this.tabs.setIndex(tabIndex);
      } else {
        // toggle checkbox status
        this.props.actions.filterCategoryToggle(
          catId,
          this.props.dataType,
          isChecked(this.props, catId)
        );
      }
    }
  };

  resetFilter = () => {
    this.reset();
    this.props.actions.filterReset();
  };

  close = () => {
    let listPageInput;

    if (this.props.checkedCatsCount > 0) {
      // Show filtered items
      listPageInput = { ids: this.props.matchingItemsId, dataType: this.props.itemDataType };
    } else {
      // Back to "all" list
      listPageInput = { dataType: this.props.itemDataType };
    }
    /* if (this.props.itemDataType === DATA_TYPE_USERPROJECTS) {
      this.props.actions.navigate(USER_DATA_PAGE_KEY, {
        inputs: [listPageInput],
        hasFilter: true,
        filterEnabled: true,
        filterCatsCount: this.props.checkedCatsCount,
      });
    } else */ {
      this.props.actions.navigate(LIST_PAGE_KEY, {
        inputs: [listPageInput],
        hasFilter: true,
        filterEnabled: true,
        filterCatsCount: this.props.checkedCatsCount,
      });
    }
    this.props.actions.hideFilterDialog();
  };

  getTabsData = () =>
    this.props.topCats.map((topCat) => ({
      key: topCat.id,
      isCurrentTab: () => this.isTopCatSelected(topCat.id),
      getTabContent: () => (
        <div className={'fd-top-cat' + (this.isTopCatSelected(topCat.id) ? ' fd-selected' : '')}>
          {this.getCheckbox(topCat, true)}
          <div className="fd-top-cat-right">
            {topCat.lump.iconClassName && (
              <div className="fd-top-cat-icon">
                <span className={topCat.lump.iconClassName} />
              </div>
            )}
            <div>{topCat.title}</div>
          </div>
        </div>
      ),
    }));

  getResultsLabel(count, dataType, labels) {
    let dataTypeLabel = labels.data[dataType][count > 1 ? 'plural' : 'singular'],
      foundLabel = labels.filterDialog.found[count > 1 ? 'plural' : 'singular'];

    return dataTypeLabel + foundLabel;
  }

  getBackButton = (overrideClass) => (
    <span
      id="fd-back-button"
      className={'material-icons arrow_back ' + overrideClass}
      onClick={this.close}
    ></span>
  );

  renderTitleBar() {
    let resultsCount;
    if (Array.isArray(this.props.matchingItemsId)) {
      resultsCount = this.props.matchingItemsId.length;
    }
    if (this.props.checkedCatsCount) {
      return (
        <div className="container-fd-header flex-row">
          {this.getBackButton('back-button-active')}
          <div className="fd flex-col">
            <div className="fd-top-bar flex-row flex-align-center">
              <span className="return-btn" onClick={this.close}>
                {this.props.labels.filterDialog.showResults}
              </span>
              <span className="clear-btn" onClick={this.resetFilter}>
                {this.props.labels.filterDialog.clear}
              </span>
            </div>
            <div className="fd-bottom-bar flex-row">
              <span className="results-counter">{resultsCount}</span>
              <span className="fd-results-label">
                {this.getResultsLabel(resultsCount, this.props.itemDataType, this.props.labels)}
              </span>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="container-fd-header-disable flex-row flex-align-center">
          {this.getBackButton()}
          <span className="filter-label">{this.props.labels.filterDialog.title.toUpperCase()}</span>
        </div>
      );
    }
  }

  render() {
    return (
      <div
        id="filter-dialog"
        className={'content-font' + (this.props.selectedTopCatId ? ' fd-has-topcat-selected' : '')}
      >
        {this.renderTitleBar()}

        <Tabs
          ref={this.setTabsRef}
          currentIndex={this.props.tabIndex}
          onTabIndexChange={this.onTabClick}
          data={this.getTabsData()}
        />

        {/* Content */}
        <div id="fd-content" ref={this.setContentEl}>
          {(this.props.content || []).map((subCat) => this.getSubCatRow(subCat, true))}
        </div>
      </div>
    );
  }
}

FilterDialog.propTypes = {
  labels: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => state[COMPONENT_KEY];
const mapDispatchToProps = (dispatch) => ({ actions: bindActionCreators(actions, dispatch) });

export default connect(mapStateToProps, mapDispatchToProps)(FilterDialog);
