import {
  CONFIG_JSON_LOADED,
  HAS_NAVIGATED,
  LANG_CHANGED,
  PROFILE_CHANGED,
  TOGGLE_LOCATION_STATUS,
  TOGGLE_MENU,
  UPDATE_PAGE_STATE,
  SOCIAL_FEED_LOADED,
  FETCH_SOCIAL_FEED,
} from 'src/store/actionTypes';

import {
  getPageCommonDefaultState,
  togglePageAfterNavigation,
  langChanged,
  profileChanged,
  toggleLocationStatus,
  toggleMenu,
  updateObject,
  updatePageState,
} from 'src/store/reducers/commons';

import { MEDIAS_PAGE_KEY } from 'src/pages/pagesKeys';

import {
  getAdConfig,
  getSocialMediaConfig,
  isSocialMediaBasicDisplay,
} from 'src/core/config-json/ConfigJsonManager';

import { getUrl } from 'src/core/data-and-assets/DataAssetsUtil';

const getDefaultState = () => ({
  ...getPageCommonDefaultState(MEDIAS_PAGE_KEY),
  twitter: { posts: [], initialFetch: true },
  //facebook: { posts: [], initialFetch: true },
  //instagram: { posts: [], initialFetch: true },
  youtube: { posts: [], initialFetch: true },
  linkedin: { posts: [], initialFetch: true },
  tiktok: { posts: [], initialFetch: true },
});

/**
 * Get media buttons from config (twitter, instagram...)
 * @param  {string} profile
 * @return {array}
 */
function getMediaButtons(mediaConf) {
  const mediaButtons = [];

  if (mediaConf) {
    Object.keys(mediaConf).forEach(function(mediaKey) {
      const { link, logo, target } = mediaConf[mediaKey];

      if (!link || !logo) {
        // Skip
        return;
      }

      mediaButtons.push({
        key: mediaKey,
        link,
        image: getUrl(logo),
        target,
      });
    });
  }
  return mediaButtons;
}

function _configJsonLoaded(state) {
  const isBasicDisplay = isSocialMediaBasicDisplay();
  const socialMediaConfig = getSocialMediaConfig(state.profile);
  
  return updateObject(state, {
    adConfig: getAdConfig(state.profile),
    hasBasicDisplay: isBasicDisplay,
    socialMediaConfig,
    mediaButtons: isBasicDisplay ? getMediaButtons(socialMediaConfig) : null,
  });
}

function _profileChanged(state, action) {
  const newValues = profileChanged(state, action, MEDIAS_PAGE_KEY, true);
  newValues.socialMediaConfig = getSocialMediaConfig(action.profile);
  return updateObject(state, newValues);
}

const _feedLoaded = (state, action) => {
  const fetchedPosts = action.posts || [];
  let feed = state[action.platform].posts;

  const { initialPayload } = action;

  if (initialPayload && (initialPayload.sinceId || initialPayload.maxId)) {
    if (initialPayload.sinceId) {
      feed = fetchedPosts.concat(feed);
    } else if (initialPayload.maxId) {
      feed = feed.concat(fetchedPosts);
    }

    return updateObject(state, {
      [action.platform]: {
        posts: feed,
        initialFetch: false,
      },
    });
  }

  return updateObject(state, {
    [action.platform]: {
      posts: action.posts,
      initialFetch: false,
    },
  });
};

const _feedLoading = (state, action) => {
  return updateObject(state, {
    [action.platform]: {
      initialFetch: action.initial,
    },
  });
};

export default (state = getDefaultState(), action) => {
  switch (action.type) {
    case CONFIG_JSON_LOADED:
      return _configJsonLoaded(state);
    case HAS_NAVIGATED:
      return togglePageAfterNavigation(state, MEDIAS_PAGE_KEY);
    case LANG_CHANGED:
      return langChanged(state, action);
    case PROFILE_CHANGED:
      return _profileChanged(state, action);
    case TOGGLE_LOCATION_STATUS:
      return toggleLocationStatus(state, action);
    case TOGGLE_MENU:
      return toggleMenu(state, action, MEDIAS_PAGE_KEY);
    case UPDATE_PAGE_STATE:
      return updatePageState(state, action, MEDIAS_PAGE_KEY);
    case SOCIAL_FEED_LOADED:
      return _feedLoaded(state, action);
    case FETCH_SOCIAL_FEED:
      return _feedLoading(state, action);

    default:
      return state;
  }
};
