// Libs
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect, Provider } from 'react-redux';
import Dialog from 'src/components/dialog/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';

// import { DISABLE_FAVORITE_BY_DATATYPE } from 'data/config/listConfig';

// app modules
import TypeBar from 'src/components/type-bar/TypeBar';
import List from 'src/components/list/List';
import * as actions from 'src/store/actions';

import '../listDialog.scss';

export const COMPONENT_KEY = 'DataListDialog';
export const CONTAINER_DOM_ID = 'data-list-dialog-container';

let store;
export function setReduxStore(_store) {
  store = _store;
}

/**
 * Display a dialog (modal) allowing user to change current language for the whole app
 * @param  {object} props
 */
class DataListDialog extends PureComponent {
  closeDialog = () => {
    this.props.actions.hideDataListDialog();
  };

  getTypedLists() {
    let typedLists = [];

    if (this.props.items) {
      let dataTypes = Object.keys(this.props.items).filter(
        (dataType) => this.props.items[dataType].length > 0
      );

      dataTypes.forEach((dataType) => {
        // Skip this dataType if it has no entry
        if (this.props.items[dataType].length) {
          const listProps = {
            items: this.props.items[dataType],
            // favorites        : this.props.favorites,
            dataType: dataType,
            actions: this.props.actions,
            labels: this.props.labels,
            isPending: this.props.isPending,
            onElementSelected: this.closeDialog,
            // displayFavorites : this.props.favIconDisabled === true ? false : !DISABLE_FAVORITE_BY_DATATYPE.includes(dataType),
            showGoToButton: this.props.showGoToButton === true,
            pageKey: this.props.pageKey,
            contextualPlaceId: this.props.placeId,
            profile: this.props.profile,
          };

          let count = listProps.items ? listProps.items.length : 0;

          typedLists.push(
            <TypeBar
              key={dataType}
              label={this.props.labels.data[dataType][count > 1 ? 'plural' : 'singular']}
              count={count}
              isClosed={dataTypes.length > 1 && count > this.props.closedIfCountAbove}
            >
              <Provider store={store}>
                <List {...listProps} />
              </Provider>
            </TypeBar>
          );
        }
      });
    }

    return typedLists;
  }

  getTitle = () => {
    let title = this.props.title;
    if (!title) {
      let dataTypes = Object.keys(this.props.items || {});
      if (dataTypes.length === 1) {
        title = this.props.labels.data[dataTypes[0]].plural;
      }
    }
    return title;
  };

  render() {


    return (
      <Dialog
        className="list-dialog dialog-min-width"
        open={this.props.isOpen}
        onClose={this.props.actions.hideDataListDialog} // important, to update `open` value in all case
      >
        <DialogTitle className="title-font modal-title">{this.getTitle()}</DialogTitle>
        <div className="generic-modal-content content-font">
          <div className="list-dialog-content">{this.getTypedLists()}</div>
        </div>
      </Dialog>
    );
  }
}

DataListDialog.propTypes = {
  showGoToButton: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  items: PropTypes.object,
  favorites: PropTypes.object,
  title: PropTypes.string,
  placeId: PropTypes.number,
  pageKey: PropTypes.string,
  profile: PropTypes.string,
  labels: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => state[COMPONENT_KEY];
const mapDispatchToProps = (dispatch) => ({ actions: bindActionCreators(actions, dispatch) });

export default connect(mapStateToProps, mapDispatchToProps)(DataListDialog);
