import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { EVENT_PAGE_SPEAKERS_AS_ACCORDION } from 'data/config/ficheConfig';
import {
  DATA_TYPE_EVENTS,
  DATA_TYPE_CONTESTS,
  DATA_TYPE_PAPERS,
  DATA_TYPE_SPEAKERS,
  DATA_TYPE_GMAP_PLACES,
} from 'data/config/dataConfig';
import { CONTEST_PAGE_KEY } from 'src/pages/pagesKeys';

import Description from 'src/components/fiche/Description';
import DetailPlaces from 'src/components/detail-places/DetailPlaces';
import DetailSpeakers from 'src/components/detail-speakers/DetailSpeakers';
//import DetailPapers from 'src/components/detail-papers/DetailPapers';
import DetailDataList from 'src/components/detail-data-list/DetailDataList';
import DetailExhibitor from 'src/components/detail-exhibitor/DetailExhibitor';
import DetailFixedTitle from 'src/components/detail-fixed-title/DetailFixedTitle';
import ShareButton from 'src/components/share-button/ShareButton';
import ExportEventButton from 'src/components/export-event-button/ExportEventButton';
import LinkButton from 'src/components/link-button/LinkButton';
import DetailDocument from 'src/components/detail-document/DetailDocument';
import DetailContributions from 'src/components/detail-contributions/DetailContributions';
import DetailSocialButton from 'src/components/detail-social-button/DetailSocialButton';
import WebsiteRow from 'src/components/fiche/WebsiteRow';
import AdSimple from 'src/components/ad-simple/AdSimple';
import List from 'src/components/list/List';
import NoteButton from 'src/components/notes/NoteButton';
import { formatLongDateTime } from 'src/core/Lang';
import { isCordovaContext } from 'src/core/util/browser';
import config from 'data/config/config';

import GenericIcon from 'src/components/generic-icon/GenericIcon';

import '../common-fiche.scss';
import './ContestPage.scss';

class ContestContent extends PureComponent {
  setScrollableContentEl = (el) => {
    this.contentContainerEl = el;
  };
  scrollTo = (value) => {
    if (this.contentContainerEl) {
      this.contentContainerEl.scrollTop = value;
    }
  };

  onClickOnFavIcon = () => {
    this.props.actions.toggleFavorite(
      this.props.item.id,
      DATA_TYPE_CONTESTS,
      this.props.isFavorite
    );
  };

  showPollDialog = () => {
    this.props.actions.showPollDialog();
  };

  renderSpeakers = ({ items, label }) =>
    !Array.isArray(items) || items.length === 0 ? null : EVENT_PAGE_SPEAKERS_AS_ACCORDION ? (
      <DetailSpeakers
        speakers={items}
        label={label}
        actions={this.props.actions}
        labels={this.props.labels}
        pageKey={CONTEST_PAGE_KEY}
      />
    ) : (
      <div>
        <div className="fiche-separator">{label}</div>
        <List
          items={items}
          dataType={DATA_TYPE_SPEAKERS}
          actions={this.props.actions}
          labels={this.props.labels}
          isFastAndUgly={false}
          displayFavorites={false}
          pageKey={CONTEST_PAGE_KEY}
        />
      </div>
    );

  /**
   * Generate page content
   */
  render() {
    let lump = this.props.item.lump;
    const preTitle = this.props.item.references.eventType
      ? this.props.item.references.eventType.title + (this.props.item.title ? ' - ' : '')
      : '';
    const title = preTitle + this.props.item.title;

    const description = this.props.item.description,
      logoUrl = this.props.item.logo,
      website = this.props.item.website,
      linkButton = lump.button,
      poll = lump.poll,
      // poll = true,
      contributions = this.props.contributions;

    return (
      <div className="fiche event content-font content-below-apptoolbar">
        {/* title */}
        <DetailFixedTitle
          title={title}
          isFav={this.props.isFavorite}
          onFavClick={this.onClickOnFavIcon}
          labels={this.props.labels}
        >
          {isCordovaContext() && config.EXPORT_EVENT && config.EXPORT_EVENT.FEATURE_ENABLED && (
            <ExportEventButton
              title={this.props.pageTitle}
              queryString={this.props.queryString}
              labels={this.props.labels}
              start_time={this.props.item.start_time}
              start_date={this.props.item.start_date}
              end_time={this.props.item.end_time}
              location={this.props.item.event_place_text}
              actions={this.props.actions}
            />
          )}
          <NoteButton
            itemTitle={this.props.item.title}
            itemId={this.props.item.id}
            dataType={DATA_TYPE_CONTESTS}
            hasNote={this.props.hasNote}
            labels={this.props.labels}
            actions={this.props.actions}
          />

          <ShareButton
            name={this.props.pageTitle}
            queryString={this.props.queryString}
            description={description}
            image={logoUrl}
            labels={this.props.labels}
            actions={this.props.actions}
            profile={this.props.profile}
            pageKey={CONTEST_PAGE_KEY}
          />
        </DetailFixedTitle>

        <div className="content" ref={this.setScrollableContentEl}>
          <div className="all-informations content-font">
            <Description
              logoFileName={logoUrl}
              dataType={DATA_TYPE_CONTESTS}
              description={description}
              isLogoFullWidth={this.props.isLogoFullWidth}
            />

            <AdSimple actions={this.props.actions} ad={lump.ad} />

            <div>
              {/* date */}
              {this.props.item.start_date && (
                <div className="fiche-related-data">
                  <div className="prop-img">
                    <div className="prop-left">
                      <div className="icon-font">
                        <GenericIcon className="link calendar-icon" />
                      </div>
                    </div>
                    <div className="prop-right evenements">
                      <div>
                        <span className="start-date">
                          {formatLongDateTime(this.props.item.start_date)}
                        </span>
                        <span className="start-time">
                          <span>{this.props.item.start_time}</span>
                          {this.props.item.end_time && (
                            <span className="end-time">
                              <span className="end-time-to">{this.props.labels.events.to}</span>
                              {this.props.item.end_time}
                            </span>
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            {/* moderators */}
            {this.renderSpeakers({
              items: this.props.item.references.moderators,
              label: this.props.labels.data.speakers.moderators,
            })}

            {/* simple speakers */}
            {this.renderSpeakers({
              items: this.props.item.references.speakers,
              label: this.props.labels.data.speakers.title,
            })}

            {/* events */}
            {Array.isArray(this.props.item.references.events) &&
              this.props.item.references.events.length > 0 && (
                <div>
                  <div className="fiche-separator">{this.props.labels.data.events.subevents}</div>
                  <List
                    items={this.props.item.references.events}
                    dataType={DATA_TYPE_EVENTS}
                    actions={this.props.actions}
                    labels={this.props.labels}
                    isFastAndUgly={false}
                    displayFavorites={false}
                    pageKey={CONTEST_PAGE_KEY}
                  />
                </div>
              )}

            {/* gmapplaces 
            {Array.isArray(this.props.item.lump.gmapplaces) &&
              this.props.item.lump.gmapplaces.length > 0 && (
                <div>
                  <div className="fiche-separator">{this.props.labels.data.gmapplaces.title}</div>
                  <List
                    items={this.props.item.references.gmapplaces}
                    dataType={DATA_TYPE_GMAP_PLACES}
                    actions={this.props.actions}
                    labels={this.props.labels}
                    isFastAndUgly={false}
                    displayFavorites={false}
                    pageKey={CONTEST_PAGE_KEY}
                    showGoToButton={true}
                  />
                </div>
              )}
            */}

            {/* document */}
            {lump.document && (
              <DetailDocument
                labels={this.props.labels}
                actions={this.props.actions}
                document={lump.document}
              />
            )}

            {/* papers/abstracts */}
            <DetailDataList
              dataType={DATA_TYPE_PAPERS}
              items={this.props.item.references.papers}
              actions={this.props.actions}
              labels={this.props.labels}
              pageKey={CONTEST_PAGE_KEY}
            />

            {/* information bar */}
            {(this.props.item.references.exhibitor || lump.access || lump.public) && (
              <div className="fiche-separator">{this.props.labels.common.information}</div>
            )}

            {/* exhibitor */}
            <DetailExhibitor
              exhibitor={this.props.item.references.exhibitor}
              actions={this.props.actions}
              labels={this.props.labels}
            />

            {/* access */}
            {lump.access && (
              <div className="free-row">
                <div className="prop-img">
                  <div className="prop-left label">
                    <div className="color-grey-dark">
                      <span>{this.props.labels.events.access}</span>
                    </div>
                  </div>
                  <div className="prop-right  name-label">
                    <div>
                      <span>{lump.access}</span>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* audience */}
            {lump.public && (
              <div className="free-row">
                <div className="prop-img">
                  <div className="prop-left label">
                    <div className="color-grey-dark">
                      <span>{this.props.labels.events.publics}</span>
                    </div>
                  </div>
                  <div className="prop-right  name-label">
                    <div>
                      <span>{lump.public}</span>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <DetailSocialButton
              data={lump.social_button}
              actions={this.props.actions}
              labels={this.props.labels}
            />

            {website && (
              <WebsiteRow website={this.props.item.website} actions={this.props.actions} />
            )}

            {linkButton && (
              <div>
                <div className="fiche-separator">{this.props.labels.common.relatedContent}</div>
                <LinkButton config={linkButton} actions={this.props.actions} />
              </div>
            )}

            {poll && (
              <div>
                <div className="fiche-separator">{this.props.labels.common.feedback}</div>
                <div className="poll-btn-container">
                  <div className="poll-btn" onClick={this.showPollDialog}>
                    <span className="poll-btn-label">
                      {this.props.labels.common.rateThisSession}
                    </span>
                    <GenericIcon className="poll-btn-icon chevron-right-icon" />
                  </div>
                </div>
              </div>
            )}

            {contributions && (
              <div>
                <div className="fiche-separator">{this.props.labels.common.contributions}</div>
                <DetailContributions
                  contributions={contributions}
                  activateRefresh={this.props.activateRefresh}
                  actions={this.props.actions}
                  labels={this.props.labels}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

ContestContent.propTypes = {
  isFavorite: PropTypes.bool,
  item: PropTypes.object,
  isLogoFullWidth: PropTypes.bool,
  // Common
  queryString: PropTypes.string.isRequired,
  pageTitle: PropTypes.string.isRequired,
  labels: PropTypes.object,
  actions: PropTypes.object,
  contributions: PropTypes.object,
};

export default ContestContent;
