import { GOLDENSUMMITS_PAGE_KEY } from 'src/pages/pagesKeys';

import GenericItemPage, { DOM_ID } from 'src/pages/generic-item-page/GenericItemPage';
import GoldenSummitContent from './GoldenSummitContent';

export default {
  key: GOLDENSUMMITS_PAGE_KEY,
  path: '/goldensummit',
  elId: DOM_ID,
  className: 'golden-summit-page',
  component: GenericItemPage,
  childComponent: GoldenSummitContent,
  relatedDataToFetch: ['exhibitor'],
};
