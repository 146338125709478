import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import config from 'data/config/config';

import GenericIcon from 'src/components/generic-icon/GenericIcon';

import './SaveContactButton.scss';

class SaveContactButton extends PureComponent {
  render() {
    if (
      config.CONTACTS.SAVE_CONTACT_TO_DEVICE.FEATURE_ENABLED !== true ||
      !global.isCordovaContext
    ) {
      return null;
    }

    if (Array.isArray(config.CONTACTS.SAVE_CONTACT_TO_DEVICE.RESTRICTIONS)) {
      const restriction = config.CONTACTS.SAVE_CONTACT_TO_DEVICE.RESTRICTIONS.find(
        (restr) => restr.pageKey === this.props.pageKey
      );
      if (restriction) {
        // A restriction has been defined for this page
        if (restriction.for.indexOf(this.props.profile) !== -1) {
          // Restriction applies for this profile, so skip rendering share button
          return null;
        }
      }
    }
    return (
      <GenericIcon
        layout='div'
        className="save-contact-btn share"
        title="Share"
        onClick={() => {
          this.props.actions.saveContactToDevice(this.props.item);
        }}
      />
    );
  }
}

SaveContactButton.propTypes = {
  item: PropTypes.object.isRequired,
  // common props
  labels: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  profile: PropTypes.string.isRequired,
  pageKey: PropTypes.string.isRequired,
};

export default SaveContactButton;
